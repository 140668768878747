import { ReactElement } from 'react'; 
import { Leader } from '../../store';
import { getNormalizedLeaders } from '../../utils/scheduleUtils';
import { CabButton, CabIcon, CabModal, CabTextInput, CabTooltip } from '@CabComponents';
import { Box, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { emailRegex } from '../../constants';
import colors from '../../colors';
import { IoWarning } from 'react-icons/io5';
import { useCabinetText } from '../../CabinetContext';

interface Props {
  isOpen: boolean;
  leaders: Leader[];
  onEmailsSubmitted: (newLeaders: Leader[]) => void;
  onCancel: () => void;
  additionalText?: string;
}

interface FormInput {
  leaders: { id: number; email: string }[];
}

export const LeaderEmailsModal = ({
  isOpen, leaders: allLeaders, onEmailsSubmitted, onCancel, additionalText,
}: Props): ReactElement => {
  const leaders = allLeaders.filter(l => !l.is_shared);
  const sharedLeaders = allLeaders.filter(l => l.is_shared);

  const emailForm = useForm<FormInput>({ defaultValues: {
    leaders: leaders.map(l => ({ id: l.id, email: l.email })),
  } });
  const { control, handleSubmit: formSubmit, formState: { errors } } = emailForm;

  const [title, body, placeholder] = useCabinetText([
    'missing-email-title',
    'missing-email-body',
    'missing-email-placeholder',
  ]);

  const handleSubmit = async (v: FormInput) => {
    const normalizedLeaders = getNormalizedLeaders(leaders);
    await onEmailsSubmitted(v.leaders.map(l => {
      return { ...normalizedLeaders[l.id], ...l };
    }));
  };

  return (
    <>
      <CabModal
        open={isOpen}
        onClose={onCancel}
        sx={{ '& .MuiPaper-root': { minWidth: 500 } }}
        title={title}
        text={body}
        actionButtons={<>
          <CabButton buttonType='secondary' onClick={onCancel}>
            Cancel
          </CabButton>
          <CabButton onClick={formSubmit(handleSubmit)}>Save</CabButton>
        </>}
      >
        {additionalText && (
          <Typography marginTop={2} color={colors.black700}>
            {additionalText}
          </Typography>
        )}
        <Box display="flex" flexDirection="column" marginTop={4} gap={1}>
          {leaders.map((leader, index) => (
            <Box key={leader.id} display="flex" flexDirection="row" alignItems="center">
              <Box flex={5}>
                <Typography>
                  {leader.first_name + ' ' + leader.last_name}
                </Typography>
              </Box>
              <Box flex={7}>
                <Controller
                  name={`leaders.${index}.id`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <input {...field} hidden />
                  )}
                />
                <Controller
                  name={`leaders.${index}.email`}
                  control={control}
                  rules={{ required: true, pattern: emailRegex }}
                  render={({ field: { ref, ...field } }) => (
                    <CabTextInput
                      {...field}
                      placeholder={placeholder} 
                      required
                      fullWidth
                    />
                  )}
                />
                {errors.leaders && errors.leaders[index]?.email && (
                  <Typography color="error" marginLeft={1}>
                    {errors.leaders[index]?.email?.type === 'required' ? 'Email is required'
                      : errors.leaders[index]?.email?.type === 'pattern' ? 'Please enter a valid email address'
                        : null}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
          {sharedLeaders.map((leader, index) => (
            <Box key={leader.id} display="flex" flexDirection="row" alignItems="center" gap={2} marginTop={1}>
              <Box flex={5}>
                <Typography>
                  {leader.first_name + ' ' + leader.last_name}
                </Typography>
              </Box>
              <Box display="flex" flex={7} flexDirection="row" alignItems="center" gap={1}>
                <CabIcon Icon={IoWarning} />
                <Typography>
                  Email must be added by
                </Typography>
                <CabTooltip
                  title="This person can only be updated by the user who shared them with you"
                  placement="top"
                >
                  <Typography marginLeft={-0.5} sx={{ textDecoration: 'underline' }}>
                    owner
                  </Typography>
                </CabTooltip>
              </Box>
            </Box>
          ))}
        </Box>
      </CabModal>
    </>
  );
};

export default LeaderEmailsModal;